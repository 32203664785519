import { useTranslation } from "react-i18next"



export default function ArmStoneView() {

    const { t } = useTranslation();

    return (
        <h1>{ t("menu_armstone")}</h1>
    )
}
