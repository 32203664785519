
import { useNavigate } from "react-router-dom";
import "./MenuButton.scss";

export interface MenuButtonProps {
    label: string;
    url: string;
}

export default function MenuButton({label, url}: MenuButtonProps) {

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate(url);
    }

    return (
        <div className="MenuButton" onClick={handleButtonClick} >
            {label}
        </div>
    )
}
