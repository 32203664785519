import { useTranslation } from "react-i18next";
import { Button } from "primereact/button"

import "./LangSelection.scss";
import AutoOverlay from "../core/AutoOverlay";
import LangButton from "./LangButton";


export default function LangSelection() {

    const { i18n } = useTranslation();
    const activeLocale = i18n.resolvedLanguage; 

    const { t } = useTranslation();

    const headerTemplate = (
        <div className="LangSelection-Header">
            {t("choose_lang")}
        </div>
    );


    return (
        <div className="LangSelection">
            <AutoOverlay headerTemplate={headerTemplate}>
                <div className="LangSelection-Body">
                    <LangButton 
                        label="English" 
                        flagImg="/gb.webp"  
                        selected={activeLocale === "en"}
                        onClick={() => i18n.changeLanguage("en")}
                    />
                    <LangButton 
                        label="Česky" 
                        flagImg="/cz.webp"  
                        selected={activeLocale === "cz"}
                        onClick={() => i18n.changeLanguage("cz")}
                    />
                </div>
            </AutoOverlay>
        </div>
    );
}