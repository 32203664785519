
import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from './store'

interface AuthState {
    loggedIn: boolean;
    user: {
        username: string | null;
    }
}

const initialState: AuthState = {
    loggedIn: false,
    user: {
        username: null
    }
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateLoginState: (state, action) => {
            state.loggedIn = action.payload;
        },
        updateUsername: (state, action) => {
            state.user.username = action.payload;
        }
    },
})

export const { updateLoginState, updateUsername } = authSlice.actions

export const selectIsLoggedIn = (state: RootState) => state.auth.loggedIn;
export const selectUsername = (state: RootState) => state.auth.user.username;

export default authSlice.reducer