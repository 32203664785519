import axios, { AxiosError, AxiosResponse } from "axios";
import { GenericNetworkError, UnautorizedAccessError } from "./Errors";

export interface IAuthStateHandler {
    onLoggedIn: (username: string) => void;
    onLoggedOut: () => void;
}

/**
 * Network Service Abstract Class
 */
export abstract class BaseService {

    constructor(private authState: IAuthStateHandler) {}

    protected async post(url: string, data?: any): Promise<AxiosResponse> {
        let response: AxiosResponse;
        try {
            response = await axios.post(url, data);
            return response;
        }
        catch(e: any) {
            if(e instanceof AxiosError) {
                return this.handleAxiosError(e);
            } else {
                throw e;
            }
        }
    }

    private handleAxiosError(e: AxiosError): AxiosResponse {
        if(e.response === undefined ) {
            throw new GenericNetworkError();
        } else {
            this.validateResponse(e.response);
            return e.response;    
        }
    }

    protected validateResponse(response: AxiosResponse) {
        // If not authorized - logout user in FE
        if(response.status === 401) {
            this.notifyLoggedOut();
            throw new UnautorizedAccessError();
        } else {
            return response;
        }
    }

    protected notifyLoggedIn(username: string, jwt: string) {
        this.applyNewJWT(jwt);
        this.authState.onLoggedIn(username);
    }

    protected notifyTokenRefresh(jwt: string) {
        this.applyNewJWT(jwt);
    }

    protected notifyLoggedOut() {
        this.authState.onLoggedOut();
        this.applyNewJWT("");
    }

    private applyNewJWT(jwt: string) {
        axios.defaults.headers["Authorization"] = `Bearer ${jwt}`
    }
}
