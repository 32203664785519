
import { useRef } from "react";
import "./AutoOverlay.scss";

export interface AutoOverlayProps {
    headerTemplate: any;
    children: any;
}

export default function AutoOverlay({headerTemplate, children}: AutoOverlayProps) {

    const refAutoOverlayHeader = useRef<HTMLDivElement>(null);
    const refAutoOverlayPopup = useRef<HTMLDivElement>(null);

    const handleShowOverlay = () => {
        if(refAutoOverlayHeader.current === null || refAutoOverlayPopup.current === null)
            return;
        const headerHeight = refAutoOverlayHeader.current.offsetHeight;
        refAutoOverlayPopup.current.style.setProperty("height", "auto");
        const height = refAutoOverlayPopup.current.clientHeight;
        refAutoOverlayPopup.current.style.setProperty("height", "");
        refAutoOverlayPopup.current.style.setProperty("--header-height", `${headerHeight}px`);
        refAutoOverlayPopup.current.style.setProperty("--popup-height", `${height}px`);
        setTimeout(() => {
            refAutoOverlayPopup.current?.classList.add("AutoOverlay-Popup--Show");
        })
    }

    const handleHideOverlay = () => {
        if(refAutoOverlayPopup.current === null)
            return;
        refAutoOverlayPopup.current.classList.remove("AutoOverlay-Popup--Show");
        refAutoOverlayPopup.current.style.setProperty("--popup-height", "");       
    }

    return (
        <div 
            className="AutoOverlay" 
            onMouseEnter={handleShowOverlay} 
            onMouseLeave={handleHideOverlay} 
        >
            <div className="AutoOverlay-Header" ref={refAutoOverlayHeader} >
                { headerTemplate }
            </div>
            <div className="AutoOverlay-Popup" ref={refAutoOverlayPopup} onClick={handleHideOverlay} >
                { children }
            </div>
        </div>
    );
}
