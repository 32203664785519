import { useTranslation } from "react-i18next";
import "./UserAccountMenu.scss";
import AutoOverlay from "../core/AutoOverlay";
import { NetAPI } from "../network/NetworkManager";
import { Toast } from 'primereact/toast';
import { useRef } from "react";
import { useSelector } from "react-redux";
import { selectUsername } from "../state/AuthSlice";


export default function UserAccountMenu() {

    const { t } = useTranslation();

    const toast = useRef<Toast>(null);

    const handleLogoutClick = async () => {
        await NetAPI.auth.logout();
        setTimeout(() => {
            toast.current?.show({
                severity: "info",
                summary: t("login-form.state.logout.header"),
                detail: t("login-form.state.logout.detail"),
                closable: false
            });   
        })
    }

    const username = useSelector(selectUsername);

    const headerTemplate = (
        <div className="UserAccountMenu-Header">
            <span>{t("account_popup_header", {login: username})}</span>
        </div>
    )

    return (
        <>
        <div className="UserAccountMenu">
            <AutoOverlay headerTemplate={headerTemplate}>
                <div className="UserAccountMenu-PopupMenu">
                    <div className="UserAccountMenu-MenuItem">
                        { t("user_acc_change_password") }
                    </div>
                    <div className="UserAccountMenu-MenuItem" onClick={handleLogoutClick} >
                        { t("user_acc_logout") }
                    </div>
                </div>
            </AutoOverlay>
        </div>
        <Toast ref={toast} appendTo={document.body}/>
        </>
    );
}
