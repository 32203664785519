

import "./AppLogo.scss";

export default function AppLogo() {

    return (
        <div className="AppLogo">
            <span>Boltec CZ</span>
        </div>
    )
}
