import i18n from "i18next";                      
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import LocaleEN from "./locale-en.json";
import LocaleCS from "./locale-cs.json";

i18n.use(detector).use(initReactI18next).init({
    lng: "en",
    fallbackLng: "en",
    debug: true,
    interpolation: {
        escapeValue: false
    },
    resources: {
        en: {
            translation: LocaleEN
        },
        cz: {
            translation: LocaleCS
        }
    }
});
