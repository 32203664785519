import './App.scss';
import AppHeader from './header/AppHeader';
import AppSidebar from './sidebar/AppSidebar';
import { Outlet } from "react-router-dom";
import LoginForm from './forms/LoginForm';
import { selectIsLoggedIn } from './state/AuthSlice';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ErrorHelper } from './helper/ErrorHelper';


function App() {

    const isLoggedIn = useSelector(selectIsLoggedIn);

    useEffect(() => {
        ErrorHelper.hookErrorHandler();
    }, []);

    return (
        <main className="Main">
            <div className="App">
                <AppHeader />
                <div className='App-Body'>
                    <AppSidebar />
                    <Outlet />
                </div>
                <LoginForm visible={! isLoggedIn} />
            </div>
        </main>
    );
}

export default App;
