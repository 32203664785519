import { useTranslation } from "react-i18next";


export default function TemplateView() {

    const { t } = useTranslation();

    return (
        <h1>{ t("menu_templates") }</h1>
    )
}
