
/**
 * This exception is raised when the JWT token is timed out
 */
export class UnautorizedAccessError extends Error {

}


/**
 * This exception is raised when the form data are invalid
 */
export class InvalidFormDataError extends Error {

}

/**
 * Generic Network Error
 */
export class GenericNetworkError extends Error {

}
