

export class ErrorHelper {


    static hookErrorHandler() {
        window.addEventListener("error", (event: ErrorEvent) => {
            const body = { message: this.formatErrorObject(event.error) };
            const headers = {
                type: 'application/json',
            };
            const blob = new Blob([JSON.stringify(body)], headers);
            navigator.sendBeacon('/api/log/frontend', blob);            
        })

        window.addEventListener("unhandledrejection", (ev: PromiseRejectionEvent) => {                        
            const body = { message: this.formatErrorObject(ev.reason) };
            const headers = {
                type: 'application/json',
            };
            const blob = new Blob([JSON.stringify(body)], headers);
            navigator.sendBeacon('/api/log/frontend', blob);            
        });
    }

    private static formatErrorObject(errorObject: any) {
        if(errorObject instanceof Error) {
            return `Message: ${errorObject.message}\nStack Trace:\n${errorObject.stack}`;
        } else {
            return JSON.stringify(errorObject, undefined, 4);
        }
    }
}
