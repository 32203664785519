
import "./LangButton.scss";

export interface LangButtonProps {
    label: string;
    selected: boolean;
    flagImg: string;
    onClick: () => void;
}

export default function LangButton({label, selected, flagImg, onClick}: LangButtonProps) {

    const className = selected ? "LangButton LangButton-Selected"  : "LangButton" 

    return (
        <div className={className} onClick={onClick} >
            <img src={flagImg} alt="flag" />
            <span> { label } </span>
        </div>
    )
}
