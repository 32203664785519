import { BaseService } from "./BaseService";

export class AuthService extends BaseService {

    async login(username: string, password: string): Promise<boolean> {
        const response = await this.post("/api/login", {username, password});
        if(response.status === 200) {
            this.notifyLoggedIn(response.data.user.username, response.data.authorisation.token);
            return true;
        } else {
            return false;
        }
    }

    async refreshToken(): Promise<void> {
        const response = await this.post("/api/refresh");
        this.notifyTokenRefresh(response.data.authorisation.token);
    }

    async logout() {
        await this.post("/api/logout");
        this.notifyLoggedOut();
    }
}
