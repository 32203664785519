import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import "primereact/resources/themes/lara-light-teal/theme.css";
import { Route, Routes, HashRouter } from 'react-router-dom';
import CustomerView from './views/CustomerView';
import ArmStoneView from './views/ArmStoneView';
import TankView from './views/TankView';
import TemplateView from './views/TemplateView';
import LogView from './views/LogView';

import "/node_modules/primeflex/primeflex.css";

import { store } from './state/store'
import { Provider } from 'react-redux'


import "./i18n/config";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <HashRouter>
                <Routes>
                    <Route path="/" element={<App />} >
                        <Route path="customers" element={<CustomerView />} />
                        <Route path="armstone" element={<ArmStoneView />} />
                        <Route path="tanks" element={<TankView />} />
                        <Route path="templates" element={<TemplateView />} />
                        <Route path="logs" element={<LogView />} />
                    </Route>
                </Routes>
            </HashRouter>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
