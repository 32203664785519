import { useTranslation } from "react-i18next";


export default function TankView() {

    const { t } = useTranslation();

    return (
        <h1>{ t("menu_tanks") }</h1>
    )
}
