import type { Store } from "@reduxjs/toolkit";
import { AuthService } from "./AuthService";
import { updateLoginState, updateUsername } from "../state/AuthSlice";

/**
 * Network Layer Facade Class
 */

const REFRESH_JWT_TIMEOUT = 5 * 1000;

export class NetAPI {

    private static store: Store | undefined;    
    private static authAPI = new AuthService(this);

    static initialize() {
        import("../state/store").then(m => {
            this.store = m.store;
        });
    }

    static get auth() { return this.authAPI; }

    static onLoggedIn (username: string) {
        if(this.store !== undefined) {
            const dispatch = this.store.dispatch;
            dispatch(updateLoginState(true));
            dispatch(updateUsername(username));

            this.startJWTRefreshTimer();
        }
    }

    static onLoggedOut() {
        if(this.store !== undefined) {
            const dispatch = this.store.dispatch;
            dispatch(updateLoginState(false));
            dispatch(updateUsername(null));

            this.stopJWTRefreshTimer();
        }
    }

    private static handleJWTTimer: number = -1;

    private static startJWTRefreshTimer() {
        if(this.handleJWTTimer >= 0) {
            window.clearTimeout(this.handleJWTTimer);
        }
        this.handleJWTTimer = window.setTimeout(() => this.refreshJWTTokenTick(), REFRESH_JWT_TIMEOUT);
    }

    private static stopJWTRefreshTimer() {
        window.clearTimeout(this.handleJWTTimer);
        this.handleJWTTimer = -1;
    }

    private static async refreshJWTTokenTick() {
        await this.auth.refreshToken();
        this.handleJWTTimer = window.setTimeout(() => this.refreshJWTTokenTick(), REFRESH_JWT_TIMEOUT);
    }
}

NetAPI.initialize();
