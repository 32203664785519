import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext"
import { Password } from 'primereact/password';
import { useLayoutEffect, useRef, useState } from "react";
import { Toast } from 'primereact/toast';

import { useTranslation } from "react-i18next";
import { NetAPI } from "../network/NetworkManager";

export interface LoginFormProps {
    visible: boolean;
}

export default function LoginForm({ visible }: LoginFormProps) {

    const { t } = useTranslation();
    const toast = useRef<Toast>(null);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = () => {
        NetAPI.auth.login(username, password).then(result => {
            if (result === true) {
                toast.current?.show({
                    severity: 'success',
                    summary: t("login-form.state.success.header"),
                    detail: t("login-form.state.success.detail"),
                    closable: false
                });
            } else {
                toast.current?.show({
                    severity: 'error',
                    summary: t("login-form.state.error.header"),
                    detail: t("login-form.state.error.detail"),
                    closable: false
                });
            }
        });
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            handleLogin();
        }
    }

    const footer = (
        <Button label={t("login-form.login")} onClick={handleLogin} />
    );

    useLayoutEffect(() => {
        setUsername("");
        setPassword("");
    }, [visible]);

    return (
        <>
            <Dialog
                closable={false}
                header={t("login-form.header")}
                visible={visible}
                onHide={() => { }}
                appendTo={document.body}
                footer={footer}
                style={{ width: 500 }}
                modal={true}
            >
                <div className="flex flex-column gap-4">
                    <div className="flex flex-column gap-2">
                        <label htmlFor="username">{t("login-form.username")}</label>
                        <InputText
                            id="username"
                            autoComplete="off"
                            autoCorrect="off"
                            autoFocus
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    <div className="flex flex-column gap-2">
                        <label htmlFor="password">{t("login-form.password")}</label>
                        <Password
                            id="password"
                            feedback={false}
                            inputStyle={{ width: "100%" }}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </div>
            </Dialog>
            <Toast ref={toast} />
        </>
    );
}
