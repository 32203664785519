import LangSelection from "./LangSelection";


import "./AppHeader.scss";
import AppLogo from "./AppLogo";
import UserAccountMenu from "./UserAccountMenu";


export default function AppHeader() {

    return (
        <div className="AppHeader">
            <AppLogo />
            <div />
            <LangSelection />            
            <UserAccountMenu />
        </div>
    );
}
