
import { useTranslation } from "react-i18next";
import "./AppSidebar.scss";
import MenuButton from "./MenuButton";

const MENU_ITEMS = [
    { label: "menu_customers", url: "/customers" },
    { label: "menu_armstone", url: "/armstone" },
    { label: "menu_tanks", url: "/tanks" },
    { label: "menu_templates", url: "/templates" },
    { label: "menu_logs", url: "/logs" }
];

export default function AppSidebar() {

    const { t } = useTranslation();

    return (
        <div className="AppSidebar">
            { MENU_ITEMS.map(mi => {
                return (
                    <MenuButton key={mi.url} label={t(mi.label)} url={mi.url} />
                )
            })}
        </div>
    );
}